import React, { useEffect } from "react"

import BlogPostTemplate from "../Templates/blogPostTemplate"
import { ContentWrapper } from "../containers/alpacked.style"
import Layout from "../containers/Layout"
import SEO from "../components/SEO"
import homeTemplate from "./index"
import { navigate } from "gatsby"
import { withUnpublishedPreview } from "gatsby-source-prismic"

const CaseStudy = ({ location }) => {
  useEffect(() => {
    navigate(
      `/ci-cd-and-ai-trends-stay-updated-in-2024?utm_source=(direct)&utm_medium=pdf&utm_content=lead_magnet_devops_as_a_service`
    )
  }, [])

  return (
    <>
      <SEO
        location={location}
        title="Alpacked DevOps Company"
        shortDesc="DevOps as a Service, Costs & Teams & Philosophy"
        description="DevOps-as-a-Service_Costs_Teams_Philosophy"
      />
      <Layout>
        <ContentWrapper>
          <iframe
            src="/DevOps-as-a-Service_Costs_Teams_Philosophy.pdf"
            width="100%"
            height="1000"
            title="DevOps as a Service, Costs & Teams & Philosophy"
          />
        </ContentWrapper>
      </Layout>
    </>
  )
}

export default withUnpublishedPreview(CaseStudy, {
  templateMap: {
    page: BlogPostTemplate,
    homepage: homeTemplate,
    prismicPage: BlogPostTemplate,
    prismicHomepage: homeTemplate,
  },
})
